import { combineReducers } from 'redux';

import algoRegistryReducer from './algoRegistryReducer';
import userInputs from './algorithmInputsReducer';
import ApiReducers from './apiReducer';
import userReducer from './authReducer';
import dateRangeReducer from './dateRangeReducer';
import algoRunReducer from './simulationOutputReducer';
import validationInputs from './validationsUserInputReducer';
import userActionReducer from './userActionReducer';

export default combineReducers({
  queries: ApiReducers,
  userInputs: userInputs,
  validationsInputs: validationInputs,
  dateRangeAlgorithms: dateRangeReducer,
  lastAlgorithmRegistry: algoRegistryReducer,
  authData: userReducer,
  lastAlgorithmRunnedRegistry: algoRunReducer,
  userSelectedTab: userActionReducer,
});
