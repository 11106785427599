const INITIAL_STATE = { userSelectedTab: null};


const selectedTabReducer = (state=INITIAL_STATE, action) => {
  switch (action.type) {
  case 'USER_SELECTED_TAB':
    return {userSelectedTab: action.payload };
  default:
    return state;
  }
};

export default selectedTabReducer;