import api from '../apis/api';

// get username for the App tab:
export const fetchUserName = language => {
  return async dispatch => {
    const response = await api.get(`/api/v1/users/getUserName?lang=${language}`, {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'GET_USERNAME', payload: response.data.message });
  };
};

// used for the Stocks input:
export const fetchStocks = () => {
  return async dispatch => {
    const response = await api.get('/api/v1/markets/find-all?sort=name', {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'GET_STOCKS', payload: response.data.message });
  };
};

// used for the Market Viewer tab:
export const fetchStockDetail = language => {
  return async dispatch => {
    const response = await api.get(`/api/v1/markets/get-stock-viewer-data?lang=${language}`, {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'GET_STOCK_VIEWER_DATA', payload: response.data.message });
  };
};

// used in the release tab:
export const fetchReleases = language => {
  return async dispatch => {
    const response = await api.get(`/api/v1/releases?sort=-date&lang=${language}`);
    dispatch({ type: 'GET_RELEASES', payload: response.data.message });
  };
};

export const resetQueriedData = () => {
  return {
    type: 'RESET_CORE_DATA',
  };
};

// date range algorithms used in dateRange component:
// not longer needed for fundamental as it no longer depends on dates.

export const getAnalystDates = () => {
  return async dispatch => {
    const response = await api.get('/api/v1/input-dates/analyst-input-dates', {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'ANALYST_DATES', payload: response.data.message });
  };
};

export const getPriceDates = () => {
  return async dispatch => {
    const response = await api.get('/api/v1/input-dates/prices-input-dates', {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'PRICE_DATES', payload: response.data.message });
  };
};

// register last run component to reset inputs in redux:
export const algorithmRegistry = algorithmName => {
  return {
    type: 'ALGO_REGISTRY',
    payload: algorithmName,
  };
};

// for algorithm inputs:
export const resetInputs = () => {
  return {
    type: 'RESET_INPUTS',
  };
};

export const stocksInput = stocks => {
  return {
    type: 'STOCKS_INPUT',
    payload: stocks,
  };
};

export const startDateInput = date => {
  return {
    type: 'START_DATE_INPUT',
    payload: date,
  };
};

export const endDateInput = date => {
  return {
    type: 'END_DATE_INPUT',
    payload: date,
  };
};

export const benchmarkInput = benchmark => {
  return {
    type: 'BENCHMARK_INPUT',
    payload: benchmark,
  };
};

export const rfRateInput = rfRate => {
  return {
    type: 'RF_RATE_INPUT',
    payload: rfRate,
  };
};

export const forecastInput = forecastInput => {
  return {
    type: 'FORECAST_INPUT',
    payload: forecastInput,
  };
};

export const portfolioBudgetInput = BudgetInput => {
  return {
    type: 'PORTFOLIO_BUDGET_INPUT',
    payload: BudgetInput,
  };
};

export const stockDistributionInput = stockDistrib => {
  return {
    type: 'STOCK_DISTRIBUTION_INPUT',
    payload: stockDistrib,
  };
};

export const colorReportInput = color => {
  return {
    type: 'REPORT_COLOR_INPUT',
    payload: color,
  };
};

export const recipientDataInput = userData => {
  return {
    type: 'RECIPIENT_DATA_INPUT',
    payload: userData,
  };
};

export const userOpinionInput = userOpinion => {
  return {
    type: 'USER_OPINION_INPUT',
    payload: userOpinion,
  };
};

export const tradeInput = msg => {
  return {
    type: 'USER_TRADE_INPUT',
    payload: msg,
  };
};

export const cashBudgetInput = msg => {
  return {
    type: 'CASH_BUDGET_INPUT',
    payload: msg,
  };
};

export const methodologyInput = methodology => {
  return {
    type: 'METHODOLOGY_INPUT',
    payload: methodology,
  };
};

export const budgetAccumulator = msg => {
  return {
    type: 'BUDGET_ACC',
    payload: msg,
  };
};

export const resetOpinionInput = () => {
  return {
    type: 'RESET_OPINION_INPUT',
  };
};

export const resetTradeInput = () => {
  return {
    type: 'RESET_TRADE_INPUT',
  };
};

// validation inputs:
export const resetValidations = () => {
  return {
    type: 'RESET_VALIDATIONS',
  };
};

export const stocksInputVal = msg => {
  return {
    type: 'STOCKS_USER_INPUT',
    payload: msg,
  };
};

export const datesInputVal = msg => {
  return {
    type: 'DATES_USER_INPUT',
    payload: msg,
  };
};

export const benchmarkInputVal = msg => {
  return {
    type: 'BENCHMARK_USER_INPUT',
    payload: msg,
  };
};

export const rfRateInputVal = msg => {
  return {
    type: 'RFRATE_USER_INPUT',
    payload: msg,
  };
};

export const forecastInputVal = msg => {
  return {
    type: 'FORECAST_USER_INPUT',
    payload: msg,
  };
};

export const portfolioBudgetInputVal = msg => {
  return {
    type: 'PORTFOLIO_BUDGET_USER_INPUT',
    payload: msg,
  };
};

export const stockDistributionInputVal = msg => {
  return {
    type: 'STOCK_DISTRIBUTION_USER_INPUT',
    payload: msg,
  };
};

export const reportColorInputVal = msg => {
  return {
    type: 'REPORT_COLOR_USER_INPUT',
    payload: msg,
  };
};

export const recipientDataInputVal = msg => {
  return {
    type: 'RECIPIENT_DATA_USER_INPUT',
    payload: msg,
  };
};

export const userOpinionInputVal = msg => {
  return {
    type: 'OPINION_USER_INPUT',
    payload: msg,
  };
};

export const tradeInputVal = msg => {
  return {
    type: 'TRADE_USER_INPUT',
    payload: msg,
  };
};

export const cashBudgetInputVal = msg => {
  return {
    type: 'CASH_BUDGET_USER_INPUT',
    payload: msg,
  };
};

export const methodologyInputVal = msg => {
  return {
    type: 'METHODOLOGY_USER_INPUT',
    payload: msg,
  };
};

// save the simulation output in redux:
export const simulationOutput = output => {
  return {
    type: 'SIMULATION_OUTPUT',
    payload: output,
  };
};

// get all saved simulations:
export const getAllSimulations = () => {
  return async dispatch => {
    const response = await api.get('/api/v1/saveAlgorithm/?sort=-date', {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'GET_SAVED_SIMULATIONS', payload: response.data.message });
  };
};

// get number saved simulations:
export const getNumberSavedSimulations = () => {
  return async dispatch => {
    const response = await api.get('/api/v1/saveAlgorithm/numberSimulations', {
      credentials: 'include',
      withCredentials: true,
    });
    dispatch({ type: 'GET_NUMBER_SIMULATIONS', payload: response.data.message });
  };
};

// get selected item from sidebar:
export const selectedTab = item => {
  return {
    type: 'USER_SELECTED_TAB',
    payload: item,
  };
};